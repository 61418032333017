
const load = () => {
    const menu = document.querySelector('.mod_navigation a.notfall.submenu');
    if (menu) {
        menu.addEventListener('click', (event) => {
            event.preventDefault();
            menu.parentNode.classList.toggle('active');
        });
    }
};

// Called as soon as registered, so DOM may not have been loaded yet
if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", load)
} else {
    load();
}
