import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['block']
    static values = { max: Number }

    connect () {
        this.observer = new ResizeObserver(this.resize.bind(this));
        this.observer.observe(this.element);
        this.resize();
    }

    disconnect () {
        this.observer?.disconnect();
    }

    resize () {
        const display = this.blockTarget.getBoundingClientRect().height < (this.lineHeight() * 2) ? 'block' : '';

        if (this.blockTarget.style.display != display) {
            this.blockTarget.style.display = display;
            this.dispatch('resize');
        }
    }

    lineHeight () {
        const lineHeight = window.getComputedStyle(this.blockTarget).getPropertyValue('line-height');

        if ('normal' === lineHeight) {
            return Number.parseFloat(window.getComputedStyle(this.blockTarget).getPropertyValue('font-size')) * 1.2;
        }

        return lineHeight;
    }
}
